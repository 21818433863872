import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { STOCK_ITEM } from "../../../queries";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { MdArrowBackIosNew } from "react-icons/md";

const StockItem = ({ setError }) => {
  const navigate = useNavigate();
  const SKU = useParams().SKU;

  const stockItemQuery = useQuery(STOCK_ITEM, {
    variables: { SKU: SKU, brand: "Valdilana" },
  });

  if (stockItemQuery.loading) {
    return (
      <div>
        <Container
          fluid
          className="py-5 min-vh-100 text-center"
          style={{ backgroundColor: "#000" }}
        >
          <Row className="align-items-center my-5 py-5">
            <Col xs={{ span: 10, offset: 1 }}>
              <Row className="text-center py-5 small">
                <Col>
                  <Spinner animation="border" variant="light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  const stockItem = stockItemQuery.data.findStockItemWithQty;

  if (!stockItem) {
    return (
      <div>
        <Container
          fluid
          className="py-5 text-light min-vh-100"
          style={{ backgroundColor: "#000" }}
        >
          <Row className="align-items-center my-5 py-5">
            <Col xs={{ span: 10, offset: 1 }}>
              <Row className="text-center py-5 small">
                <Col>
                  <Link
                    to="/catalog"
                    className="text-decoration-none text-light"
                  >
                    catalog
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>Item not Found!</Col>
              </Row>
              <Row className="text-end small my-5">
                <Col style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                  <MdArrowBackIosNew /> back
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Container fluid className="min-vh-100 text-light">
        <Container
          fluid
          className="pt-5 text-light "
          style={{ backgroundColor: "#000" }}
        >
          <Row className="align-items-center text-center small my-5 pt-5">
            <Col xs={{ span: 10, offset: 1 }}>
              <Link to="/catalog" className="text-decoration-none text-light">
                catalog
              </Link>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col xs={{ span: 10, offset: 0 }} md={{ span: 4, offset: 0 }}>
              <Row className="text-end display-4 mt-5">
                <Col>{stockItem.SKU}</Col>
              </Row>
              <Row className="text-end lead">
                <Col>{stockItem.description}</Col>
              </Row>
              <Row className="text-end lead mb-5">
                <Col>{stockItem.weight} g/mt</Col>
              </Row>
              <Row className="text-end small my-5">
                <Col style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                  <MdArrowBackIosNew /> back
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }}>
              <img
                className="w-100"
                src={`https://metamoda-public.s3.me-central-1.amazonaws.com/media/${stockItem.SKU}.JPG`}
                alt={stockItem.SKU}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/placeholder.jpg";
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default StockItem;
